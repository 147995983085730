import data from './videos.json'
import { NavBar } from './molecules/NavBar'
import { Footer } from './molecules/Footer'
import { Grid } from './organisms/Grid'

import { iVideo } from './types'
import { useEffect, useState } from 'react'
import 'bulma/css/bulma.css'
import './App.css'

const SECTION_STYLE = {minHeight:'calc(100vh - 180px)', paddingBottom:0, paddingTop:'2.5rem'}

const getDuration = (duration:string) => {
    try {
        const suffix = duration.substring(2)
        const hours:any[] = suffix.split('H')
        const minutes = hours[suffix.includes('H') ? 1 : 0].split('M')
        const seconds = minutes[suffix.includes('M') ? 1 : 0]
    
        const result = Number(suffix.includes('H') ? hours[0]*3600 : 0) + Number(suffix.includes('M') ? minutes[0]*60 : 0) + Number(seconds.split('S')[0])
        const time = Number(result)    
        return time
    } catch(e) { return 0 }
}


export const App = () => {
	const [ videos, setVideos ] = useState<iVideo[]>([])

    const goHome = () => {
        setVideos([])
    }

    useEffect(() => {
        const videos:iVideo[] = data.flat().map((v)=> ({
            ...v,
            views: Number(v.views),
            likes: Number(v.likes),
            comments: Number(v.comments),
            date: new Date(v.date),
            length: getDuration(v.duration)
        }))
        .filter(({ comments }) => comments > 5)


        setVideos(videos)
    }, [])

	return <>
        <NavBar goHome={goHome}/>
        <div className='section' style={SECTION_STYLE}>
            <Grid videos={videos} setVideos={setVideos} />
        </div>
        <Footer/>
    </>
}
