/* eslint-disable jsx-a11y/anchor-is-valid */

import { useMediaQuery } from 'react-responsive'
import { mediaQuery } from '../organisms/Grid'
import { useState } from 'react'

const Docs = () => <div className='navbar-end'>
    <div className='navbar-item'>
        <div className='buttons'>
            <a className='button is-light' href='/blog/founders-library-intro'>
                <strong>What's this?</strong>
            </a>
        </div>        
    </div>
</div>        


interface iNavBar { goHome():void }
export const NavBar = ({ goHome }: iNavBar) => {
    const [ isActive, setActive ] = useState(false)
    const isMobile = useMediaQuery({ query: mediaQuery })

    return <nav className='navbar is-black' role='navigation' aria-label='main navigation'>
        <div className='container'>
            <div className='navbar-brand'>
                <a className='navbar-item' onClick={goHome}>
                    <img src='SocialQ.png' style={{ height:36, maxHeight: 'none' }} alt={'SocialQ logo'}/>
                    <p className='navbar-item' style={{ fontSize: '2em', color:'white' }} > Founder's Library </p>
                </a>

                <a 
                    role='button' 
                    className={`navbar-burger ${isActive ? 'is-active': ''}`}
                    aria-label='menu' 
                    aria-expanded='false' 
                    data-target='navbarBasicExample'
                    onClick={()=> setActive(!isActive)}
                >
                    <span aria-hidden='true'></span>
                    <span aria-hidden='true'></span>
                    <span aria-hidden='true'></span>
                </a>
            </div>

            <div 
                className={`navbar-menu ${isActive ? 'is-active navbar-menu-active': ''}`} 
                style={{ maxWidth:1200, marginRight:'auto', background:'#0A0A0A' }}
            > { isMobile && <Docs /> } </div>

            { !isMobile && <Docs /> }
        </div>
    </nav>
}
