import { useState } from 'react'
import { Filter } from '../atoms/Filter'
import { iVideo } from '../types'
import { useMediaQuery } from 'react-responsive'

type FilterNames = 'Channel' | 'Genre' | 'Dimension' | 'Sorting'  


const MOTIVATION = 'Motivation'
const LEARNING = 'Learning'
const CURIOSITY = 'Intriguing'
const INSPIRATION = 'Inspiration'
const ENTERTAINMENT = 'Entertainment'

const GENRES = [MOTIVATION, CURIOSITY, ENTERTAINMENT, INSPIRATION, LEARNING]
export const FILTER_NAMES = { 
    Channel: 'Channel', 
    Genre: 'Genre', 
    Dimension: 'Dimension', 
    Sorting: 'Sorting'
}

export const DIMENSIONS = [
    'Information', // Learning (3), Inspiration (2).
    'Relevance', // Learning (1), Motivation (1). 
    'Curiosity', // Curiosity (3), Entertainment (1).
    'Value', // Learning (2).
    'Admiration', // Motivation (2), Inspiration (3).
    'Entertainment', // Entertainment (3).
    'Variety', // Curiosity (1), Inspiration (1).
    'Emotiality', // Motivation (3).
    'Controversy', // Curiosity (2).
    'Relatibility', // Entertainment (2).
]

// const learningGenre = (m:number[]) =>  m[3]*3 +  m[1]*2 + m[2]*2 + m[0]
const learningGenre = (m:number[]) =>  m[3]*3 + m[1]*2 + m[0] 
const motivationGenre = (m:number[]) => m[2]*-3 + m[4]*-2 + m[1]*-1 
const curiosityGenre = (m:number[]) => m[2]*3 + m[3]*-2 + m[4]*-1
const inspirationGenre = (m:number[]) => m[4]*-3 + m[0]*2 + m[1]*-1
const entertainmentGenre = (m:number[]) => m[0]*-3 + m[4]*2 + m[2]*1

const MOTIVATION_DIMENSIONS = [7, 4, 6]
const LEARNING_DIMENSIONS = [3, 1, 0]
const CURIOSITY_DIMENSIONS = [2, 8, 9]
const INSPIRATION_DIMENSIONS = [4, 0, 6]
const ENTERTAINMENT_DIMENSIONS = [5, 4, 2]


interface iFilters {
    videos:iVideo[] 
    dimensions:number[]
    channelNames:string[]
    filteredVideos:iVideo[]
    setFilter(filter:string | undefined):void
    setDimensions(dimensions:number[]):void 
    setFilteredVideos(videos:iVideo[]):void
} 

export const Filters = ({ videos, dimensions, channelNames, filteredVideos, setFilter, setFilteredVideos, setDimensions }: iFilters) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 1216px)' })
    const [filterNames, setFilterNames] = useState(FILTER_NAMES)

    const selectGenre = (videos:iVideo[], item:string):iVideo[] => {
        if(item === LEARNING) {
            setDimensions(LEARNING_DIMENSIONS)
            return videos.sort(({ metrics: a }, { metrics:b }) => 
                    learningGenre(a) > learningGenre(b) ? -1 : 1
                ).map((v, _, a) => ({
                    ...v, 
                    score: learningGenre(v.metrics)/learningGenre(a[0].metrics) 
                }))
        }

        if(item === MOTIVATION) {
            setDimensions(MOTIVATION_DIMENSIONS)
            return videos.sort(({ metrics: a }, { metrics:b }) => 
                motivationGenre(a) > motivationGenre(b) ? -1 : 1
            ).map((v, _, a) => ({
                ...v, 
                score: motivationGenre(v.metrics)/motivationGenre(a[0].metrics) 
            }))
        }

        if(item === CURIOSITY) {
            setDimensions(CURIOSITY_DIMENSIONS)
            return videos.sort(({ metrics: a }, { metrics:b }) => 
                curiosityGenre(a) > curiosityGenre(b) ? -1 : 1
            ).map((v, _, a) => ({
                ...v, 
                score: curiosityGenre(v.metrics)/curiosityGenre(a[0].metrics) 
            }))
        }

        if(item === INSPIRATION) {
            setDimensions(INSPIRATION_DIMENSIONS)
            return videos.sort(({ metrics: a }, { metrics:b }) => 
                inspirationGenre(a) > inspirationGenre(b) ? -1 : 1
            ).map((v, _, a) => ({
                ...v, 
                score: inspirationGenre(v.metrics)/inspirationGenre(a[0].metrics) 
            }))
        }

        setDimensions(ENTERTAINMENT_DIMENSIONS)
        return videos.sort(({ metrics: a }, { metrics:b }) => 
            entertainmentGenre(a) > entertainmentGenre(b) ? -1 : 1
        ).map((v, _, a) => ({
            ...v, 
            score: entertainmentGenre(v.metrics)/entertainmentGenre(a[0].metrics) 
        }))
    }

    const sortDimension = (item:string) => {
        const index = DIMENSIONS.findIndex((d) => item === d)

        if(index < 5) {
            setFilteredVideos(
                (filteredVideos.length ? filteredVideos : [...videos])
                .sort(({ metrics:a }, { metrics:b }) => a[index] > b[index] ? -1 : 1)
            )
        }

        else {
            setFilteredVideos(
                (filteredVideos.length ? filteredVideos : [...videos])
                .sort(({ metrics:a }, { metrics:b }) => a[index - 5] > b[index - 5] ? 1 : -1)
            )
        }

        setDimensions([index, ...dimensions.filter((d) => (d%5) !== (index%5))])
    }

    const handleSorting = (item:string) => {
        if(item === 'Date') return filteredVideos.sort(({ date: a }, { date: b }) => a > b ? -1 : 1)
        if(item === 'Views') return filteredVideos.sort(({ views: a }, { views: b }) => a > b ? -1 : 1)
        if(item === 'Likes') return filteredVideos.sort(({ likes: a }, { likes: b }) => a > b ? -1 : 1)
        if(item === 'Comments') return filteredVideos.sort(({ comments: a }, { comments: b }) => a > b ? -1 : 1)
        if(item === 'Duration') return filteredVideos.sort(({ length: a }, { length: b }) => a > b ? -1 : 1)        
        else return filteredVideos
    }

    const handleFilters = (filter:FilterNames, item:string) => {
        setFilter(filter)

        if(filter === 'Genre') {
            const items = selectGenre(filteredVideos.length ? filteredVideos : [...videos], item)
            setFilteredVideos(items)
        }

        if(filter === 'Channel') {
            const channelVideos = (filteredVideos.length ===  videos.length ? filteredVideos : videos)
            .filter(({ channelName }) => item === channelName)
            setFilteredVideos(channelVideos)
        }

        if(filter === 'Dimension') sortDimension(item)
        if(filter === 'Sorting') setFilteredVideos([...handleSorting(item)])

        if(filter !=='Channel'){
            setFilterNames({...FILTER_NAMES, Channel:filterNames.Channel, [filter]: item.substring(0,16)})
        } else {
            setFilterNames({...filterNames, Channel:item.substring(0,16)})
        }
    
    }

    const clearFilters = () => {
        setFilterNames(FILTER_NAMES)
        setFilteredVideos([])
        setDimensions([0,1,2,3,4])
        setFilter(undefined)
    }

    return <div className='columns' style={{textAlign:'center', marginBottom:'1.5rem'}}>
        <div className='column'>
            <Filter 
                name={filterNames.Genre} 
                filters={GENRES} 
                select={tag => handleFilters('Genre', tag)} 
            />
        </div>

        <div className='column' style={isDesktop ? {marginLeft:20} : {}}>
            <Filter 
                name={filterNames.Channel} 
                filters={channelNames} 
                select={topic => handleFilters('Channel', topic)} 
            />
        </div>

        <div className='column'>
            <Filter 
                filters={DIMENSIONS} 
                name={filterNames.Dimension}
                select={rating => handleFilters('Dimension', rating)} 
            />
        </div>

        <div className='column'>
            <Filter 
                disabled={filterNames.Channel===FILTER_NAMES.Channel}
                name={filterNames.Sorting} 
                select={time => handleFilters('Sorting', time)} 
                filters={['Date', 'Views', 'Likes', 'Comments', 'Duration']} 
            />
        </div>

        <div className='column' style={isDesktop ? {marginLeft:-40, marginRight:20} : {}}>
            <button 
                className='button is-ghost has-text-grey-light'
                onClick={clearFilters}
            >Clear filters</button>
        </div>
    </div>
}
