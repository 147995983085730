/* eslint-disable jsx-a11y/anchor-is-valid */

import { useMediaQuery } from 'react-responsive'
import { mediaQuery } from '../organisms/Grid'
import { useEffect, useState } from 'react'
import { iVideo } from '../types'
import { DIMENSIONS } from './Filters'

const CARD_STYLE = {
    backgroundColor: 'rgb(48, 48, 48)',
    borderRadius: 12,
    margin: 'auto',
    marginBottom: '1.5em',
    border: '1px solid white'
}

const HEADER_STYLE = { 
    backgroundColor: 'rgb(72, 72, 72)', 
    borderTopLeftRadius: 12, 
    borderTopRightRadius: 12 
}

const DESCRIPTION_STYLE = { color:'whitesmoke', marginTop:'1.5rem', marginBottom:'2.5rem' }
const DIMENSION_STYLE = { maxWidth:'80%', display:'flex', color:'white', marginTop:7, marginBottom:7 }
const MOBILE_DIMENSION_STYLE = { maxWidth:'100%', display:'flex', color:'white', margin:7 }

type Color = 'link' | 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'text'

interface iDimensionBar { dimension:number|null, index:number }
const DimensionBar = ({ dimension, index }: iDimensionBar) => {
    const isMobile = useMediaQuery({ query: mediaQuery })

    const getColor = (value:number|null|undefined):Color => {
        if(!value) return 'text'
        if(value < 35) return 'danger'
        if(value < 50) return 'warning'
        if(value < 65) return 'success'
        else return 'info'
    }

    const getValue = (dimension:number|null|undefined, index:number) => {
        if(!dimension) return 0

        const value = index < 5 ? dimension : -dimension
        if(value < -0.25) return 0
        if(value > 0.25) return 100
        else return Math.round((value + 0.25)*200)
    }

    const [color, setColor] = useState<Color>()
    const [value, setValue] = useState<number>()


    useEffect(() => setColor(getColor(value)), [value])
    useEffect(() => setValue(getValue(dimension, index)), [dimension, index])

    return <div style={!isMobile ? DIMENSION_STYLE : MOBILE_DIMENSION_STYLE}>
        <div style={{flex: isMobile ? 2 : 1}}> {DIMENSIONS[index]} </div>
        <div style={{flex: isMobile ? 3 : 2}}>
            <progress className={`progress is-${color}`} value={value} max='100' />
        </div>
        <div style={{flex:1, paddingLeft:15}}> { value }% </div>
    </div>
}

const VideoTitle = ({video}: {video:iVideo}) => <header 
    className='card-header' 
    style={HEADER_STYLE}
>
    <a 
        target='_blank' 
        rel='noreferrer'
        href={`https://www.youtube.com/watch?v=${video.videoId}`} 
        style={{padding:0}} 
        className='card-footer-item' 
    >
        <p 
            className='card-header-title' 
            style={{color:'white', fontSize:'1.25rem'}}
        > { video.title } </p>
    </a>
</header>

const VideoInfo = ({ video: { channelId, channelName, length, date }}: {video:iVideo}) => {
    const getDuration = (length:number) => {
        const date = new Date(0)
        date.setSeconds(length)

        let index = 12
        if(length < 3600) index = 14
        if(length < 600) index = 15

        return date.toISOString().substring(index, 19)
    }

    return <p style={{marginTop:'1rem'}}>
        {   <a href={`https://www.youtube.com/@${channelId}`} target='_blank' rel='noreferrer'>
                <strong style={{color:'lightskyblue', marginRight:8}}> { channelName } </strong>
            </a>
        }

        <small style={{color:'grey'}}> 
            <i>  {`${new Date(date).getDate()}/${new Date(date).getMonth()+1}/${new Date(date).getFullYear()}`} </i> 
        </small>

        <small style={{color:'lightgrey', marginLeft:16 }}> 
            { getDuration(length) } 
        </small>
    </p>
}

export const numberWithCommas = (x:number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

interface iVideoFooter { video: iVideo, getSimilar(metrics:number[]):void, similarChannels(channel:string):void }
const VideoFooter = ({ video, getSimilar, similarChannels }: iVideoFooter) => {
    const isMobile = useMediaQuery({ query: mediaQuery })

    return <footer 
        className='card-footer' 
        style={{color:'white'}}
    >

        { !isMobile && <>
            <div className='card-footer-item'>
                <b> Comments: { numberWithCommas(video.comments) }  </b>
            </div>

            <div className='card-footer-item'>
                <b> Likes: { numberWithCommas(video.likes) }  </b>
            </div>

            <div className='card-footer-item'>
                <b> Views: { numberWithCommas(video.views) }  </b>
            </div>
        </>}

        { video.score && 
            <div className='card-footer-item'>
                <b> Score: <span style={{color:'#eccca2'}}> {Math.round(video.score*100)}% </span></b>
            </div>
        }

        <div className='card-footer-item'>
            <a onClick={() => similarChannels(video.channelName)} style={{color:'skyblue'}}> Similar channels </a>
        </div>

        <div className='card-footer-item'>
            <a onClick={() => getSimilar(video.metrics)} style={{color:'skyblue'}}><b> More like this </b></a>
        </div>
    </footer>
}

export interface iVideoCard {video:iVideo, dimensions:number[], getSimilar(metrics:number[]):void, similarChannels(channel:string):void }
export const MobileVideo = ({video, dimensions, getSimilar, similarChannels}: iVideoCard) => <div 
    className='card' 
    style={CARD_STYLE}
>
    <VideoTitle video={video} />

    <div className='card-image'>
        <figure className='image is-16by9'>
            <a 
                href={`https://www.youtube.com/watch?v=${video.videoId}`}
                target='_blank' 
                rel='noreferrer'
            >
                <img 
                    src={video.image} 
                    style={{objectFit:'cover'}}
                    alt='Video cover' 
                />
            </a>
        </figure>
    </div>

    <div className='card-content' style={{padding:'0.5rem'}}>
        <VideoInfo video={video}/>
        { dimensions.map((d, i) => <DimensionBar dimension={video.metrics[d % 5] || 0} index={d} key={i} />) }
    </div>

    <VideoFooter video={video} getSimilar={getSimilar} similarChannels={similarChannels}/>
</div>


export const VideoCard = ({video, dimensions, getSimilar, similarChannels}:iVideoCard) => <div className='card' style={CARD_STYLE}>
    <VideoTitle video={video} />

    <article className='media' style={{marginBottom:0}}>
        <figure className='media-left' style={{width:'40%'}}>
            <a 
                href={`https://www.youtube.com/watch?v=${video.videoId}`}
                target='_blank' 
                rel='noreferrer'
            >
                <img 
                    src={video.image} 
                    style={{objectFit:'cover'}}
                    alt='Video cover' 
                />
            </a>
        </figure>

        <div className='media-content' style={{paddingBottom:'0.5rem', paddingRight:'1rem'}}>
            <div className='content'>
                <VideoInfo video={video} />
            </div>

            <div className='content' style={DESCRIPTION_STYLE}> 
                { video.description.split('\n')[0].substring(0,250) }
                <b> ...more </b>
            </div>

            { dimensions.map((d, i) => <DimensionBar dimension={video.metrics[d % 5] || 0} index={d} key={i} />) }
         </div>
    </article>

    <VideoFooter video={video} getSimilar={getSimilar} similarChannels={similarChannels}/>
</div>
