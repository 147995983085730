/* eslint-disable jsx-a11y/anchor-is-valid */

const footerStyle = {height:80, padding:'40px 0px 0px 0px', backgroundColor:'transparent'}

export const Footer = () => <footer className='footer' style={footerStyle}>
    <div className='content has-text-centered'>
        <p style={{color:'white'}}>
            <strong style={{color:'white'}}>Founder's Library </strong> 
            was created by 
            <a href='#' style={{color:'skyblue'}}> amVizion </a>. 
        </p>
    </div>
</footer>
