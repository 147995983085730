import { VideoCard, MobileVideo, iVideoCard } from '../molecules/Video'
import { Channel, iChannel } from '../molecules/Channel'
import { useMediaQuery } from 'react-responsive'
import { DIMENSIONS, Filters } from '../molecules/Filters'
import { useState, useEffect } from 'react'
import channelData from '../channels.json'
import { iVideo } from '../types'


export const mediaQuery = '(max-width: 768px)'
const Row = ({ video, dimensions, getSimilar, similarChannels }: iVideoCard) => {
    const isMobile = useMediaQuery({ query: mediaQuery })

    return <div className='columns'>
        <div className='column'>
            {
                !isMobile
                    ?   <VideoCard 
                            video={video} 
                            dimensions={dimensions} 
                            getSimilar={getSimilar}
                            similarChannels={similarChannels}
                        />
                    :   <MobileVideo 
                            video={video} 
                            dimensions={dimensions} 
                            getSimilar={getSimilar}
                            similarChannels={similarChannels}
                        />
            }
        </div>
    </div>
}

const euclideanDistance = (A:number[], B:number[]) => A.reduce((d, i, idx) => 
    d + (i - B[idx])**2, 0
)**(1/2)

// const HOME_VIDEOS = ['gfEEcssu304', 'R2fAYn8R318', 'RKRJ3-PT3jA', 'TkWf2vyvZCc', 'Ng7LIRDhwwg'] // May 15th
const HOME_VIDEOS = [
    'hMSiaUCJkmc', 
    'dAnF0tk0di8', 
    'ICnFtfN-sUc', 
    'jnORCh1HiGE',  
    '13pnH_8cBUM', 
    'ihT5BHm4z2E', 
    'oO1OBs2zaWU', 
    'r9eRlVVMsFY',  
    'iBzy_hDb2W4', 
    'QKPgBAnbc10',
    'sT3isR06-fg', 
    'amdXa3CfzHw'
]

export interface iVideos { videos: iVideo[], setVideos(videos: iVideo[]): void }
export const Grid = ({ videos }: iVideos) => {
    const [ filter, setFilter ] = useState<string>()
    const [ dimensions, setDimensions ] = useState([0, 1, 2, 3, 4])
    const [ channelNames, setChannelNames ] = useState<string[]>([])
    const [ filteredVideos, setFilteredVideos ] = useState<iVideo[]>([])
    const [ showChannels, setShowChannels ] = useState(false)

    const [ channels, setChannels ] = useState<iChannel[]>([])

    useEffect(() => {
        if (channelNames.length) return
        const names = [...new Set(videos.map(({ channelName }) => channelName!))]
        setChannelNames(names)
    }, [videos, channelNames])

    useEffect(() => setShowChannels(false), [ filter ])

    const getSimilar = (metrics:number[]) => {
        const similaritySort = videos.sort(({metrics:a}, {metrics:b}) => 
            euclideanDistance(a, metrics) > euclideanDistance(b, metrics) ? 1 : -1
        )
        setFilteredVideos([...similaritySort])
        if(!filter) setDimensions([6,9,5,8,2])
    }

    const similarChannels = (channelName:string) => {
        const channel = channelData.find(({ title }) => title === channelName)
        if(!channel?.dimensions) return 

        const { dimensions } = channel
        const similaritySort = channelData.sort(({dimensions:a}, {dimensions:b}) => 
            euclideanDistance(a, dimensions) > euclideanDistance(b, dimensions) ? 1 : -1
        )

        setChannels([...similaritySort])
        setShowChannels(true)
    }

    const channelVideos = ({ title, link }:iChannel) => {
        const channelVideos = videos.filter(({ channelName }) => channelName === title)

        setFilteredVideos([...channelVideos])

        if(!channelVideos.length) return window.open(link, '_blank')
        setShowChannels(false)

        // TODO: Update - clear filters.
    }

    return <div className='container'>
        <Filters
            videos={videos}
            dimensions={dimensions}
            channelNames={channelNames}
            filteredVideos={filteredVideos}
            setFilter={setFilter}
            setDimensions={setDimensions}
            setFilteredVideos={setFilteredVideos}
        />

        {   
            // Video Grid.
            !!filteredVideos.length && !showChannels && filteredVideos.filter((_, i) => i < 10)
            .map((video, i) => <Row 
                key={i} 
                video={video} 
                dimensions={dimensions} 
                getSimilar={getSimilar}
                similarChannels={similarChannels}
            />)
        }   
        
        {   
            // Channel Grid.
            showChannels && channels.filter((_, i) => i < 20)
            .map((channel, i) => <div className='columns' key={i}>
                <div className='column'>
                    <Channel 
                        element={channel} 
                        data={channelData}
                        setCards={setChannels}
                        getVideos={channelVideos}
                        getSimilar={similarChannels}
                        names={DIMENSIONS}

                    />
                </div>
            </div>)
        }   

        {
            // Home Grid.
            !filteredVideos.length && !showChannels && HOME_VIDEOS
            .map((id, key) => {
                const video = videos.find(({ videoId }) => id === videoId)!
                if(video) return <Row 
                    video={video} 
                    key={key} 
                    dimensions={[6,9,5,8,2]}
                    getSimilar={getSimilar}
                    similarChannels={similarChannels}
                />
                return null
            })
        }
    </div>
}
