/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { mediaQuery } from '../organisms/Grid'
import { numberWithCommas } from './Video'

export interface iChannel {
    title: string
    description: string
    image: string
    secondaryImage: string
    date: string
    link: string
    filters: string[]
    dimensions: number[]
    topDimension: string
    sorting: { [key:string]:number }
}

const CARD_STYLE = {
    backgroundColor: 'rgb(48, 48, 48)',
    borderRadius: 12,
    margin: 'auto',
    marginBottom: '1.5em',
    border: '1px solid white'
}

const HEADER_STYLE = { 
    backgroundColor: 'rgb(72, 72, 72)', 
    borderTopLeftRadius: 12, 
    borderTopRightRadius: 12 
}

const DESCRIPTION_STYLE = { color:'whitesmoke', marginTop:'1rem', marginBottom:'1.5rem' }
const DIMENSION_STYLE = { maxWidth:'80%', display:'flex', color:'white', marginTop:7, marginBottom:7 }

type Color = 'link' | 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'text'

interface iDimensionBar { dimension:number, label:string }
const DimensionBar = ({ dimension, label }: iDimensionBar) => {
    const [color, setColor] = useState<Color>()
    const [edit, setEdit] = useState(false)

    const getColor = (value:number):Color => {
        if(value < 0.25) return 'danger'
        if(value < 0.50) return 'warning'
        if(value < 0.75) return 'success'
        else return 'info'
    }

    useEffect(() => setColor(getColor(dimension)), [dimension])

    return <div style={{...DIMENSION_STYLE, margin: edit ? 12 : 7 }}>
        <div style={{flex: 1}}>
            { 
                <a style={{color:'white'}} onDoubleClick={() => setEdit(true)}> { label } </a>
            }
        </div>

        <div style={{flex: 2}}>
            <progress className={`progress is-${color}`} value={Math.round(dimension*100)} max='100' />
        </div>
        <div style={{flex:1, paddingLeft:15}}> { Math.round(dimension*100) }% </div>
    </div>
}

const Title = ({element: { title, link }}: { element: iChannel }) => <header 
    className='card-header' 
    style={HEADER_STYLE}
>
    <a 
        target='_blank' 
        rel='noreferrer'
        href={link} 
        style={{padding:0}} 
        className='card-footer-item' 
    >
        <p 
            className='card-header-title' 
            style={{color:'white', fontSize:'1.25rem'}}
        > { title } </p>
    </a>
</header>

interface iCardInfo { element:iChannel, filterDimensions(dimension:string):void }
const Info = ({ element, filterDimensions }:iCardInfo) => {
    const [date, setDate] = useState<string>()

    useEffect(() => { 
        try{
            const channelDate = new Date(element.date)
            setDate(channelDate.toDateString())
        } catch(e) {}
    }, [element])

    return <p style={{marginTop:'1rem'}}>
        <i style={{color:'grey', marginRight:12 }}>  { date } </i> 

        <a  onClick={() => filterDimensions(element.topDimension)}>
            <span className='tag is-info is-size-6' style={{marginRight:12}}> 
                Dimension { element.topDimension } 
            </span>
        </a>

        { 
            element.filters.filter((_, i) => i < 3).map((filter:string, i:number) => 
                <span className='tag is-light is-size-6' key={i} style={{marginRight:12}}> 
                    { filter } 
                </span>
            )
        }
    </p>
}

interface iFooter { 
    element: iChannel
    isMobile?: boolean
    getVideos(channel:iChannel):void 
    getSimilar(channelName:string):void
}

const Footer = ({ element, isMobile, getSimilar, getVideos }:iFooter) => {
    return <footer 
        className='card-footer' 
        style={{color:'white'}}
    >
        { 
            !isMobile && Object.entries(element.sorting).map(([key, value], i) => <div className='card-footer-item' key={i}>
                <span className='has-text-light'> {key} </span>: { numberWithCommas(value as number) } 
            </div>)
        }

        <div className='card-footer-item'>
            <a 
                onClick={() => getSimilar(element.title)} 
                style={{color:'skyblue'}}
            >
                Similar channels
            </a>
        </div>

        <div className='card-footer-item'>
            <a 
                onClick={() => getVideos(element)} 
                style={{color:'skyblue'}}
            >
                <b> Channel videos </b>
            </a>
        </div>
    </footer>
}

export interface iCard {
    data: iChannel[]
    element: iChannel
    names: string[]
    setCards(cards:iChannel[]):void
    getVideos(channel:iChannel):void
    getSimilar(metrics:string): void 
}

export const MobileCard = ({element, getVideos, getSimilar}:iCard) => {
    const [image, setImage] = useState<string>()
    const [expand, setExpand] = useState<boolean>(false)

    useEffect(() => setImage(element.image), [element])

    return <div 
        className='card' 
        style={CARD_STYLE}
    >
        <Title element={element}/>

        <div className='card-image'>
            <figure className='image is-16by9'>
                <a href={element.link} target='_blank' rel='noreferrer'>
                    <img 
                        src={image} 
                        style={{objectFit:'cover'}}
                        alt='Cover' 
                        onError={() => setImage(element.secondaryImage)}
                    />
                </a>
            </figure>
        </div>

        <div className='card-content' style={{padding:'0.5rem'}}>
            <div className='content' style={DESCRIPTION_STYLE}> 
                { 
                    !expand 
                        ? element.description.substring(0,250) 
                        : element.description 
                }
                { 
                    !expand && element.description.length > 250 && 
                    <b>
                        <a 
                            onClick={() => setExpand(true)} 
                            style={{color:'skyblue'}}
                        > ...more </a>
                    </b> 
                }
            </div>
        </div>

        <Footer element={element} getSimilar={getSimilar} getVideos={getVideos} isMobile/>
    </div>
}

const Card = ({element, data, names, setCards, getVideos, getSimilar}:iCard) => {
    const [expand, setExpand] = useState<boolean>(false)
    const [image, setImage] = useState<string>()

    useEffect(() => setImage(element.image), [element])

    const filterDimensions = (dimension:string) => {
        const filteredData = data.filter((d:iChannel) => d.topDimension === dimension)

        if(dimension.includes('+')){
            const sortedData = filteredData.sort(({dimensions:a}:iChannel, {dimensions:b}:iChannel) => a > b ? -1 : 1)
            setCards(sortedData)

        } else {
            const sortedData = filteredData.sort(({dimensions:a}:iChannel, {dimensions:b}:iChannel) => a > b ? -1 : 1)
            setCards(sortedData)
        }
    }

    return <div className='card' style={CARD_STYLE}>
        <Title element={element}/>

        <article className='media' style={{marginBottom:0}}> 
            <figure className='media-left' style={{ width:'30%'}}> { /* TODO: Add conditional on media. */ }
                <a href={element.link} target='_blank' rel='noreferrer'>
                    <img 
                        src={image} 
                        style={{objectFit:'cover', width:'100%', background:'white'}}
                        onError={() => setImage(element.secondaryImage)}
                        alt='Cover' 
                    />
                </a>
            </figure>

            <div className='media-content' style={{paddingBottom:'0.5rem', paddingRight:'1rem'}}>
                <div className='content'>
                    <Info element={element} filterDimensions={filterDimensions}/>
                </div>

                <div className='content' style={DESCRIPTION_STYLE}> 
                    { 
                        !expand 
                            ? element.description.substring(0,500) 
                            : element.description 
                    }
                    { 
                        !expand && element.description.length > 500 && 
                        <b>
                            <a 
                                onClick={() => setExpand(true)} 
                                style={{color:'skyblue'}}
                            > ...more </a>
                        </b> 
                    }
                </div>

                {
                    element.dimensions.map((d, i) => 
                        <DimensionBar 
                            dimension={d} 
                            label={names[i]} 
                            key={i} 
                        />
                    ) 
                }
            </div>
        </article>

        <Footer element={element} getSimilar={getSimilar} getVideos={getVideos}/>
    </div>
}

export const Channel = (props: iCard) => {
    const isMobile = useMediaQuery({ query: mediaQuery })
    return !isMobile ? <Card {...props} /> : <MobileCard {...props} />
}
